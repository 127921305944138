<template>
  <v-card class="mx-auto text-center" color="red" dark elevation="5">
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          v-if="value.length"
          :value="value"
          color="rgba(255, 255, 255, .7)"
          height="100"
          padding="20"
          stroke-linecap="round"
          smooth
          line-width="2"
        >
          <template v-slot:label="item" > Rs. {{ item.value }} </template>
        </v-sparkline>
        <v-card v-else style="background: #fff !important">
          <v-card-text>
            <h4 class="font-bold">There are no any data to show. Check back later after the sale.</h4>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-card-text>

    <v-card-text>
      <h3 class="text-white" style="font-weight: 600 !important; color: #fff !important">
        Sales Till {{ today }}
      </h3>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    today() {
      return moment(Date.now()).format("YYYY/MM/DD");
    },
  },
};
</script>
