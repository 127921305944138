<template>
  <v-container class="py-12">
    <v-row>
      <v-col cols="12" lg="3" md="4">
        <v-card class="mx-auto" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-cash-check</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Withdrawn Balance</h5>
            <h2>Rs. {{ withdrawnAmount }}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <v-card class="mx-auto" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-cash-minus</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Request Balance</h5>
            <h2>Rs. {{ requestBalance }}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <v-card class="mx-auto" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-cash-lock-open</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Available Balance</h5>
            <h2>Rs. {{availableBalance()}}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <v-card class="mx-auto" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-cash</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Total Balance</h5>
            <h2>Rs. {{ total }}</h2>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="8" md="6" sm="12">
        <wallet-chart :value="value"></wallet-chart>
      </v-col>
      <v-col lg="4">
        <bank-detail :bank_details="bank_details"></bank-detail>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import BankDetail from "../../components/wallet/bankdetails/BankDetail.vue";
import WalletChart from "../../components/wallet/WalletChart.vue";
import AvailableBalance from "../../mixins/AvailableBalance";


export default {
  components: { WalletChart, BankDetail },
 mixins: [AvailableBalance ],
  computed: {
    ...mapState({
      wallet: (state) => state.wallet,
      bank_details: (state) => state.bankDetails,
      withdrawRequests: (state) => state.withdrawRequests,
    }),

    value() {
      let arr = [];
      this.wallet.forEach((element) => {
        arr.push(element.amount);
      });
      return arr;
    },
    total() {
      return this.wallet.reduce((acc, cur) => {
        acc = acc + cur.amount;
        return acc;
      }, 0);
    },

    withdrawnAmount() {
      return this.withdrawRequests.reduce((acc, cur) => {
        if (cur.withdrawn === 1) {
          acc = acc + cur.withdraw_amount;
        }
        return acc;
      }, 0);
    },

    requestBalance() {
      return this.withdrawRequests.reduce((acc, cur) => {
        if (cur.withdraw_request === 1) {
          acc = acc + cur.withdraw_amount;
        }
        return acc;
      }, 0);
    },

  },
};
</script>

<style lang="scss">
.dashboard-card-inner {
  background: rgb(255, 34, 65) !important;
  top: -1.5rem;
  left: 1rem;
  position: absolute;
}
.font-bold {
  color: #000;
  font-weight: 600;
}
</style>
