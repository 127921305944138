export default {
    methods: {
        availableBalance(){
            let debit=this.wallet.filter((item) => item.debit === 1);
            let totalDeb=debit.reduce((acc, cur) => {
                acc = acc + cur.amount;
                return acc;
            }, 0);
            let credit=this.wallet.filter((item) => item.credit === 1);
            let totCredit=credit.reduce((acc, cur) => {
                acc = acc + cur.amount;
                return acc;
            }, 0);

            return totalDeb - totCredit;

        }
    }
}