<template>
  <div>
    <v-card elevation="5">
      <v-card-title
        >Bank Details
        <v-spacer></v-spacer>
        <v-btn rounded color="red" small dark @click="dialog = true"
          >Add Bank</v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <div v-if="bank_details.length">
        <v-card-text v-for="item in bank_details" :key="item.id">
          <div class="bank-details">
            <div class="d-flex align-items-center">
              <p class="mb-1 font-bold">Bank Name</p>
              <p class="mb-1 font-bold ml-auto">{{ item.bank_name }}</p>
            </div>
            <div class="d-flex align-items-center">
              <p class="mb-1 font-bold">Account No.</p>
              <p class="mb-1 font-bold ml-auto">{{ item.account }}</p>
            </div>
            <div class="d-flex align-items-center">
              <p class="mb-1 font-bold">Branch Location</p>
              <p class="mb-1 font-bold ml-auto">{{ item.branch }}</p>
            </div>
            <div class="d-flex align-items-center">
              <p class="mb-1 font-bold">Account Name</p>
              <p class="mb-1 font-bold ml-auto">{{ item.account_holder }}</p>
            </div>
          </div>
          <div class="text-right mt-2">
            <v-btn small text class="primary-color " @click="deleteItem(item)"
              ><v-icon small class="mr-2">mdi-delete</v-icon>Delete</v-btn
            >
          </div>
        </v-card-text>
        <v-divider class="my-2"></v-divider>
      </div>
      <div v-else>
        <v-card-text class="text-center">
          <h4 class="font-bold mb-2">No Any Banks Account Added</h4>
          <p class="mb-1">
            Please add atleast 1 Bank Account inorder to receive the money of
            your sold product.
          </p>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title> {{ formTitle }} Bank Details </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="editedItem.bank_name"
              outlined
              :rules="required"
              dense
              label="Bank Name"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.account"
              outlined
              dense
              min="1"
              :rules="required"
              label="Account No."
              type="text"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.branch"
              outlined
              dense
              :rules="required"
              label="Bank Branch"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.account_holder"
              outlined
              dense
              :rules="required"
              label="Account Name"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.remarks"
              outlined
              dense
              label="Remarks"
            ></v-text-field>
            <v-btn color="primary" @click="save()">Submit </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text class="pt-4 text-center">
          <v-icon color="error" size="50">mdi-close-circle</v-icon>
          <h2 class="font-bold py-4">Are you sure want to delete this Item?</h2>
          <h4 class="font-bold text-left">
            Please be aware that you cannot revert this process in future.
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="px-8"
            rounded
            dark
            @click="deleteItemConfirm"
            >OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    bank_details: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      required: [(v) => !!v || "This Field is required"],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add" : "Edit";
    },
    getBankInformation() {
      return this.bank_details.map((item, index) => ({
        ...item,
        sn: index + 1,
        bank_name: item.bank_name,
        account: item.account,
        branch: item.branch,
        remarks: item.remarks,
        account_holder: item.account_holder,
      }));
    },
  },

  methods: {
    ...mapActions([
      "storeBankDetails",
      "updateBankDetails",
      "deleteBankDetails",
    ]),

    edit(item) {
      let temp = this.bank_details.filter(
        (bank_detail) => bank_detail.id === item.id
      )[0];
      this.editedIndex = this.bank_details.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          let temp = this.getBankInformation;
          Object.assign(this.bank_details[this.editedIndex], this.editedItem);
          this.updateBankDetails(this.editedItem)
            .then((response) => {
              this.$store.commit("setBankDetails", this.bank_details);
              this.editedIndex = -1;
              console.log(response.status);
            })
            .catch((error) => {
              Object.assign(this.bank_details[this.editedIndex], temp[0]);
              this.editedIndex = -1;
              alert(error.message);
            });
        } else {
          this.storeBankDetails(this.editedItem).then((response) => {
            if (response.status === 400) alert(response.message);
          });
        }
        this.close();
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },
    deleteItem(item) {
      let temp = this.bank_details.filter(
        (bank_detail) => bank_detail.id === item.id
      )[0];
      this.editedIndex = this.bank_details.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let temp = this.bank_details.filter(
        (bank_detail) => bank_detail.id === this.editedItem.id
      )[0];
      this.bank_details.splice(this.editedIndex, 1);
      this.deleteBankDetails(this.editedItem.id)
        .then(() => {
          this.$store.commit("setBankDetails", this.bank_details);
        })
        .catch(() => {
          Object.assign(this.bank_details[this.editedIndex], temp);
        });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
  },
};
</script>
